import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "reduce-conceptually",
  "title": "`Reduce` Conceptually",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><inlineCode parentName="h1">{`reduce`}</inlineCode>{` Conceptually`}</h1>
    <p>{`I have this gut feeling that if I can break down `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` to the right concepts, it will click for everyone who takes this course. Maybe that's naive, but I'm going to try. Are you ready?`}</p>
    <p><inlineCode parentName="p">{`reduce`}</inlineCode>{` is a JavaScript array method that takes all the items of the array and returns a single item. It `}<em parentName="p">{`literally`}</em>{` reduces values down to a single value.`}</p>
    <p>{`Sometimes that single value is a primitive value. Like a number, a string, a boolean. Other times, it is a single `}<em parentName="p">{`reference`}</em>{`. An array, an object, a set. The key concept here is that many items are reduced, condensed, folded into a `}<em parentName="p">{`single`}</em>{` item.`}</p>
    <p>{`Now, the challenging part about `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` is `}<em parentName="p">{`how`}</em>{` we get that single item. To understand that, we need to break `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` into two components: the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function, and the iterative loop.`}</p>
    <h2>{`What is a `}<inlineCode parentName="h2">{`reducer`}</inlineCode>{` function?`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` is a pure function that receives at least two arguments: a `}<inlineCode parentName="p">{`state`}</inlineCode>{` and some `}<inlineCode parentName="p">{`value`}</inlineCode>{`, and returns the `}<inlineCode parentName="p">{`nextState`}</inlineCode>{` based on those arguments. That's it. It's not more complicated than that. It looks like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`reducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// do some logic to calculate \`nextState\``}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` nextState
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`When you start to look at it this way, you begin to recognize that `}<em parentName="p">{`lots`}</em>{` of functions are reducers. What about `}<inlineCode parentName="p">{`add`}</inlineCode>{`?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` y

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 7`}</span></code></pre></div>
    <p>{`Or what about `}<inlineCode parentName="p">{`concat`}</inlineCode>{`?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`concat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// [1, 2, 3, 4, 5, 6]`}</span></code></pre></div>
    <p>{`You see, reducers are every where and I'm guessing you're pretty comfortable using them. You just didn't know you were using them, `}<em parentName="p">{`until now`}</em>{`.`}</p>
    <h2>{`The iterative loop`}</h2>
    <p>{`A good number of array methods are designed to loop over each item in the array on which the method is called: `}<inlineCode parentName="p">{`forEach`}</inlineCode>{`, `}<inlineCode parentName="p">{`map`}</inlineCode>{`, `}<inlineCode parentName="p">{`filter`}</inlineCode>{`, `}<inlineCode parentName="p">{`sort`}</inlineCode>{`, and, of course, `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`. Each of these methods receives a `}<inlineCode parentName="p">{`callback`}</inlineCode>{` argument. That is, a function that is called with the `}<inlineCode parentName="p">{`currentItem`}</inlineCode>{` passed in as an argument. Consider `}<inlineCode parentName="p">{`forEach`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` numbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// I'm using a named function here for teaching purposes`}</span>{`
numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 1`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 2`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 3`}</span></code></pre></div>
    <p>{`In this simple example, each `}<inlineCode parentName="p">{`number`}</inlineCode>{` was passed to the callback function in order, and we used `}<inlineCode parentName="p">{`console.log`}</inlineCode>{` to log out each number. In other words, `}<inlineCode parentName="p">{`forEach`}</inlineCode>{` did the following for us:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 1`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 2`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 3`}</span></code></pre></div>
    <p>{`Do you see how we got from passing in a callback function, `}<inlineCode parentName="p">{`log`}</inlineCode>{`, and we got three calls of `}<inlineCode parentName="p">{`log`}</inlineCode>{` with the items of the array passed in?`}</p>
    <p>{`Now, how does this apply to `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`?`}</p>
    <p>{`We will learn the `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` API in depth in the next lesson, but for now, I want you to understand that the callback we pass to `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` is a reducer function. Just like the ones we just discussed. That callback receives a `}<inlineCode parentName="p">{`state`}</inlineCode>{` and the `}<inlineCode parentName="p">{`currentItem`}</inlineCode>{` in the array as its `}<inlineCode parentName="p">{`value`}</inlineCode>{` argument. It returns the `}<inlineCode parentName="p">{`nextState`}</inlineCode>{`.`}</p>
    <p>{`Here's the only "trick" about `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`. What you return, the `}<inlineCode parentName="p">{`nextState`}</inlineCode>{`, `}<strong parentName="p">{`is the `}<inlineCode parentName="strong">{`state`}</inlineCode>{` argument for the next call of the reducer`}</strong>{`. Remember how `}<inlineCode parentName="p">{`add`}</inlineCode>{` is a reducer function?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` numbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` y
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` initialState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` total `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`add`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` initialState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`What happens with each loop here? If we break it out like we did with `}<inlineCode parentName="p">{`log`}</inlineCode>{`, we get:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` initialState `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// starts at 0`}</span>{`
state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 0 + 1 returns 1`}</span>{`
state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 1 + 2 returns 3`}</span>{`
state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 3 + 3 returns 6`}</span></code></pre></div>
    <p>{`You see? `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` combines a reducer function with a loop over each item. The `}<inlineCode parentName="p">{`state`}</inlineCode>{` passed into the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` is the `}<inlineCode parentName="p">{`state`}</inlineCode>{` returned by the previous call of `}<inlineCode parentName="p">{`reducer`}</inlineCode>{`. It's just a `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` getting called over and over, nothing more special than that.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`I hope that helps clarify `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` a bit for you and got you prepared for the rest of the course. If you're still confused, `}<strong parentName="p">{`do not worry`}</strong>{`. We're going to keep breaking down `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` together until we get it, and we have enough exercises for you to go over to really master the concept.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      