import React from 'react'
import LessonLayout from '../components/LessonLayout'
import OverviewLayout from '../components/OverviewLayout'
import AuthorizedRoute from '../components/AuthorizedRoute'

export default function makeRoutesFromCourses(courses) {
  return (
    <>
      {courses.map(course => {
        const { basepath, wrapper, overview, lessons, title } = course

        return (
          <AuthorizedRoute
            component={wrapper}
            courseId={basepath}
            key={basepath}
            path={basepath}
          >
            <OverviewLayout
              basepath={basepath}
              content={overview}
              path="/"
              firstLesson={lessons[0]}
              title={`${title} Overview`}
            />
            {lessons.map((lesson, idx) => {
              const { content, path, text } = lesson

              return (
                <AuthorizedRoute
                  basepath={basepath}
                  component={LessonLayout}
                  content={content}
                  courseId={basepath}
                  key={lesson.path}
                  isFinalLesson={idx === lessons.length - 1}
                  nextLesson={lessons[idx + 1]}
                  path={path}
                  previousLesson={lessons[idx - 1]}
                  text={text}
                />
              )
            })}
          </AuthorizedRoute>
        )
      })}
    </>
  )
}
