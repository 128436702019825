import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "build-reduce-from-scratch",
  "title": "Build `reduce` from scratch",
  "published": true
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Spacer = makeShortcode("Spacer");
const Vimeo = makeShortcode("Vimeo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Build `}<inlineCode parentName="h1">{`reduce`}</inlineCode>{` from scratch`}</h1>
    <Spacer bottom={2} mdxType="Spacer">
  <Vimeo src="https://player.vimeo.com/video/607717725?h=d0edd28df7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Build `reduce` from scratch" mdxType="Vimeo" />
    </Spacer>
    <p>{`I think that it can be helpful to implement the functionality of a confusing method on our own. Figuring out how it works can help to unlock what's been complicated about it for us before. I'd like to do that now with `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`, so let's do what we've been told never to do, and mess around with the built-in `}<inlineCode parentName="p">{`Array.prototype`}</inlineCode>{`.`}</p>
    <p>{`Since JavaScript doesn't have a `}<inlineCode parentName="p">{`fold`}</inlineCode>{` method, we'll use that as the name of our implementation, like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`fold`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`When re-implementing a method in order to learn it, the first thing we can do is add the arguments for our method. What are the arguments to `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`? They are the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` callback function, and the optional `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`fold`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` initialValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Next, we can examine the API to understand what we need to achieve.`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`reducer`}</inlineCode>{` receives four arguments: the `}<inlineCode parentName="li">{`accumulatedValue`}</inlineCode>{`, the `}<inlineCode parentName="li">{`currentItem`}</inlineCode>{`, the `}<inlineCode parentName="li">{`index`}</inlineCode>{`, and the array that the method was called on`}</li>
      <li parentName="ul">{`if no `}<inlineCode parentName="li">{`initialValue`}</inlineCode>{` is provided, then the starting `}<inlineCode parentName="li">{`accumulatedValue`}</inlineCode>{` is the first item in the array and the `}<inlineCode parentName="li">{`index`}</inlineCode>{` starts at 1`}</li>
    </ul>
    <p>{`This right here paints most of the picture of our implementation:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`fold`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` initialValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` initialValue `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// setting our starting accumulation`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// \`null\` or \`undefined\` are valid \`initialValue\`s, so we must use`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// another strategy for determining if an \`initialValue\` was provided,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// the \`arguments\` object of the function`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// \`this\` is the array`}</span>{`
    index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Now loop through the items, updating our \`accumulatedValue\` with`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// the result of the \`reducer\``}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Notice how we take the current accumulatedValue and pass it as an`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// argument to the \`reducer\` callback, updating \`accumulatedValue\` for`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// the next iteration`}</span>{`
    accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`reducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accumulatedValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` accumulatedValue
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`It now becomes clear why we used the `}<inlineCode parentName="p">{`function`}</inlineCode>{` keyword instead of an arrow function when creating our method. We needed two things that only a function made with the keyword has: the `}<inlineCode parentName="p">{`arguments`}</inlineCode>{` object and `}<inlineCode parentName="p">{`this`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`this`}</inlineCode>{` is the array on which the method is called, and we need the `}<inlineCode parentName="p">{`arguments`}</inlineCode>{` object in order to properly set the first `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` for our method.`}</p>
    <p>{`My hope is that it's now clear how the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` works with each iteration. We pass the current `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` into the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function as the first argument, and then assign the result to `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` for the next iteration and finally what we return from the method.`}</p>
    <h2><inlineCode parentName="h2">{`reduceRight`}</inlineCode></h2>
    <p>{`As we learned in the API lesson, we also have a `}<inlineCode parentName="p">{`reduceRight`}</inlineCode>{` method that iterates from the end of the array towards its beginning. Let's modify our `}<inlineCode parentName="p">{`fold`}</inlineCode>{` method to be a `}<inlineCode parentName="p">{`foldRight`}</inlineCode>{` to implement this as well.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`foldRight`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` initialValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` initialValue
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// the index of last item in the array`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    accumulatedValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`reducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accumulatedValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` accumulatedValue
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The changes we made here are straightforward. First, we move our index to the end of the array. Then, if necessary, we adjust the initial `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` to be the last item in the array and also decrement our `}<inlineCode parentName="p">{`index`}</inlineCode>{`. Finally, we iterate through the array with decrements because we're moving from the end to the beginning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      