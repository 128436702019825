import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "practical-use-counts",
  "title": "Practical Use: `counts`",
  "published": true
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Spacer = makeShortcode("Spacer");
const Vimeo = makeShortcode("Vimeo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Practical Use: `}<inlineCode parentName="h1">{`counts`}</inlineCode></h1>
    <Spacer bottom={2} mdxType="Spacer">
  <Vimeo src="https://player.vimeo.com/video/596690191?h=b2446f01a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Practical use: `counts`" mdxType="Vimeo" />
    </Spacer>
    <p>{`One common reason I `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` a list of items is if I want to gather a count for each item type in the list. We do this by creating an object where the keys are based on an the item type, and the values are the counts for those items. This is our first time reducing a list to an object, and it's a very common reason to use `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`.`}</p>
    <p>{`For example, let's say I have a data sample that contains a list of numbers and I want a count of how many times that number appears in the list. Let's write a basic test for that scenario:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`describe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'counts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`it`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'should return the counts of numbers in a list'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1'`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2'`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3'`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Let's write some code that gets this test to pass. We'll start as always with our `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` skeleton mention in my `}<a parentName="p" {...{
        "href": "/learn/array-reduce/my-approach"
      }}>{`approach`}</a>{` lesson.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, with each iteration, what should our `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` do? It should check if the number is already a key in our object. If it isn't a key, set that key's value to `}<inlineCode parentName="p">{`0`}</inlineCode>{`. Then, increment the value at the key and return the object. Let's write that.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Great. Now our test passes. Also, it doesn't have to be just numbers. `}<inlineCode parentName="p">{`counts`}</inlineCode>{` can be used on any primitive value. We do run into a snag with other data structures, though, like arrays and objects.`}</p>
    <p>{`What if I wanted to let `}<inlineCode parentName="p">{`counts`}</inlineCode>{` work on objects for example? Is there something I could do to change the API so that this works, too? Let's write a failing test and then make changes to get it passing.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`it`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'should be able to work on non-primitive values'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` items `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'M'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'blue'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'M'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'S'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'L'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'blue'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'S'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` red`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` blue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`L`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`M`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Obviously these fail for multiple reasons, but one of the big ones is simply we haven't instructed `}<inlineCode parentName="p">{`counts`}</inlineCode>{` how to do anything with the objects. We need a way for `}<inlineCode parentName="p">{`counts`}</inlineCode>{` to get the `}<inlineCode parentName="p">{`key`}</inlineCode>{` it needs in order to work. How do we solve this?`}</p>
    <p>{`I think the best way to solve this is to add a `}<inlineCode parentName="p">{`getKey`}</inlineCode>{` argument. This will be a function that receives the `}<inlineCode parentName="p">{`item`}</inlineCode>{` (and more) as arguments, and determines what the `}<inlineCode parentName="p">{`key`}</inlineCode>{` should be for that iteration of the `}<inlineCode parentName="p">{`counts`}</inlineCode>{` reducer. Let's modify those tests to show that API.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`it`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'should be able to work on non-primitive values'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` items `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'M'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'blue'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'M'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'S'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'L'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'blue'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'S'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`item`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`color`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` red`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` blue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`item`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`size`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`L`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`M`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now we can adjust our `}<inlineCode parentName="p">{`counts`}</inlineCode>{` function to accommodate that argument. What's even better is that we can give it a default parameter that stringifies the item so that it continues to work with primitive values.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`getKey`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`item`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` index`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3><inlineCode parentName="h3">{`for`}</inlineCode>{` loop implementation of `}<inlineCode parentName="h3">{`counts`}</inlineCode></h3>
    <Spacer bottom={2} mdxType="Spacer">
  <Vimeo src="https://player.vimeo.com/video/596690003?h=bcb3745b9c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Refactor `counts` with a `for..of` loop" mdxType="Vimeo" />
    </Spacer>
    <p>{`As with most of the `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop implementations, this one is very similar to our reduction.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`counts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`getKey`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`item`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`of`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`entries`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` result
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      