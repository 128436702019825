import React from 'react'
import { Link } from 'gatsby'
import { bs } from '../shevy'
import { mq } from '../utils'
import Spacer from './Spacer'

export default function LearnLayout({ children, course }) {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: bs(2),

        [mq.charlie]: {
          display: 'grid',
          gridTemplateColumns: 'minmax(0, 2fr) 1fr',
        },
      }}
    >
      <div>{children}</div>
      <TableOfContents course={course} />
    </div>
  )
}

function TableOfContents({ course }) {
  return (
    <div>
      <div
        css={{
          backgroundColor: 'var(--colors-offset)',
          padding: bs(),
          [mq.charlie]: { position: 'sticky', top: bs() },
        }}
      >
        <h4>Course Contents</h4>

        <Spacer bottom={0.5}>
          <Link to={`/learn/${course.basepath}`}>Overview</Link>
        </Spacer>

        {course.lessons.length ? (
          <>
            <h5>Lessons</h5>
            <ol
              css={{
                listStylePosition: 'inside',
                marginBottom: 0,
                'li + li': { marginTop: 0 },
              }}
            >
              {course.lessons.map(lesson => (
                <li key={lesson.path}>
                  <Link to={`/learn/${course.basepath}/${lesson.path}`}>
                    {lesson.text}
                  </Link>
                </li>
              ))}
            </ol>
          </>
        ) : null}
      </div>
    </div>
  )
}
