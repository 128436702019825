import { Link } from 'gatsby'
import React from 'react'
import ShiftBy from './ShiftBy'
import shevy, { bs } from '../shevy'

export default function OtherLesson({ label, path, text }) {
  return (
    <Link
      css={{
        backgroundColor: 'var(--colors-offset)',
        borderLeft: '6px solid var(--colors-accent)',
        display: 'block',
        padding: bs(0.5),
      }}
      to={path}
    >
      <ShiftBy y={-2}>
        <strong
          css={{
            color: 'var(--colors-text)',
            display: 'inline-block',
            fontFamily: 'var(--fonts-catamaran)',
            fontSize: shevy.h4.fontSize,
            marginRight: bs(0.25),
          }}
        >
          {label}:
        </strong>
        <span>{text}</span>
      </ShiftBy>
    </Link>
  )
}
