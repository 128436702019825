import React from 'react'
import { bs } from '../shevy'
import { mq } from '../utils'
import Flex from './Flex'
import Beard from './icons/Beard'
import ScreenReaderOnly from './ScreenReaderOnly'

export default function BeardRating({ onChange, value }) {
  const [hoveredValue, setHoveredValue] = React.useState(0)

  const unsetHoveredValue = () => {
    setHoveredValue(0)
  }

  const handleClick = e => {
    onChange(e.target.value)
  }

  return (
    <fieldset
      css={{
        border: '2px solid var(--colors-accent)',
        borderRadius: 4,
      }}
    >
      <legend
        css={{
          fontFamily: 'var(--fonts-catamaran)',
          fontWeight: 'bold',
          paddingLeft: bs(0.25),
          paddingRight: bs(0.25),
        }}
      >
        How many <del css={{ textDecorationThickness: 4 }}>stars</del> beards
        would you give this course?
      </legend>

      <Flex wrap="wrap">
        {Array(5)
          .fill()
          .map((_, idx) => {
            const number = idx + 1
            const selectedOrLower = number <= value
            const hoveredOrLower = number <= hoveredValue

            return (
              // eslint-disable-next-line
              <label
                css={{
                  borderRadius: 4,
                  cursor: 'pointer',
                  display: 'block',
                  padding: bs(0.25),

                  '&:focus-within': {
                    outline: '2px solid var(--colors-accent)',
                  },
                }}
                htmlFor={`beard${number}`}
                key={idx}
                onMouseOver={() => {
                  setHoveredValue(number)
                }}
                onMouseOut={unsetHoveredValue}
              >
                <ScreenReaderOnly>
                  {number} beards
                  <input
                    id={`beard${number}`}
                    name="beards"
                    onClick={handleClick}
                    onFocus={() => {
                      setHoveredValue(number)
                    }}
                    onBlur={unsetHoveredValue}
                    type="radio"
                    value={number}
                  />
                </ScreenReaderOnly>
                <div
                  css={{
                    '> svg': {
                      fill: hoveredOrLower
                        ? 'var(--colors-accentLight)'
                        : selectedOrLower
                        ? 'var(--colors-accent)'
                        : 'var(--colors-offsetMore)',
                      transition: 'fill .2s ease',
                      width: 32,

                      [mq.bravo]: {
                        width: 50,
                      },
                    },
                  }}
                >
                  <Beard />
                </div>
              </label>
            )
          })}
      </Flex>
    </fieldset>
  )
}
