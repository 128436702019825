import React from 'react'
import OtherLesson from './OtherLesson'
import Seo from './Seo'
import Spacer from './Spacer'

export default function OverviewLayout({
  basepath,
  content: Content,
  firstLesson,
  title,
}) {
  return (
    <>
      <Seo title={title} />

      <Content />
      {firstLesson && (
        <Spacer bottom={2}>
          <OtherLesson
            label="First Lesson"
            path={`/learn/${basepath}/${firstLesson.path}`}
            text={firstLesson.text}
          />
        </Spacer>
      )}
    </>
  )
}
