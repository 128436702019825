import { Link } from 'gatsby'
import { useBool } from '@kyleshevlin/use-common'
import React from 'react'
import shevy, { bs } from '../shevy'
import Button from './Button'
import Flex from './Flex'
import OtherLesson from './OtherLesson'
import Seo from './Seo'
import Spacer from './Spacer'
import Textarea from './Textarea'
import { useInput } from './Inputs'
import BeardRating from './BeardRating'
import Checkbox from './Checkbox'
import { useAuthContext } from '../contexts/AuthContext'
import useAsyncProcess from '../hooks/useAsyncProcess'
import { useFeedbackContext } from '../contexts/FeedbackContext'

export default function LessonLayout({
  basepath,
  content: Content,
  isFinalLesson,
  nextLesson,
  previousLesson,
  text,
}) {
  return (
    <>
      <Seo title={text} />

      {previousLesson && (
        <Spacer bottom={1}>
          <Link to={`/learn/${basepath}/${previousLesson.path}`}>
            Back to the previous lesson
          </Link>
        </Spacer>
      )}

      <article>
        <Content />
      </article>

      {nextLesson && (
        <Spacer top={1.5}>
          {nextLesson && (
            <OtherLesson
              label="Next Lesson"
              path={`/learn/${basepath}/${nextLesson.path}`}
              text={nextLesson.text}
            />
          )}
        </Spacer>
      )}

      {isFinalLesson && (
        <Spacer top={1.5}>
          <FinalLesson courseId={basepath} />
        </Spacer>
      )}
    </>
  )
}

function FinalLesson({ courseId }) {
  const [isOpen, setOpen] = useBool()

  return (
    <>
      <div
        css={{
          backgroundColor: 'var(--colors-offset)',
          borderLeft: '6px solid var(--colors-accent)',
          padding: bs(),

          p: {
            margin: 0,
          },
        }}
      >
        <Flex direction="column" gap={bs()}>
          <p
            css={{
              fontFamily: 'var(--fonts-catamaran)',
              fontSize: shevy.h2.fontSize,
              fontWeight: 'bold',
              lineHeight: shevy.lineHeightSpacing(1.5),
            }}
          >
            Congratulations on completing the course!{' '}
            <span aria-label="tada" role="img">
              🎉
            </span>
          </p>
          {/* TODO: check if the user even needs to be prompted */}
          {isOpen ? (
            <FeedbackForm courseId={courseId} onCancel={setOpen.off} />
          ) : (
            <>
              <p>
                Would you be interested in providing some feedback on the
                course?
              </p>
              <div>
                <Button onClick={setOpen.on}>Sure!</Button>
              </div>
            </>
          )}
        </Flex>
      </div>
    </>
  )
}

/**
 * This form is for providing feedback about the course
 */
function FeedbackForm({ courseId, onCancel }) {
  const [, { user }] = useAuthContext()
  const { createFeedback } = useFeedbackContext()
  const [processState, setProcessState] = useAsyncProcess()
  const [rating, setRating] = React.useState(0)
  const [statement, onStatementChange] = useInput('')
  const [permission, setPermission] = useBool()

  const handleSubmit = async e => {
    e.preventDefault()

    if (!rating || !statement) return

    setProcessState.start()

    const userId = user.id
    const result = {
      course_id: courseId,
      user_id: userId,
      ...(rating > 0 && { rating }),
      ...(statement && { statement }),
      publicEndorsementPermission: permission,
    }

    try {
      const { error } = await createFeedback(result)

      if (error) {
        console.error(error)
        setProcessState.fail()
        return
      }

      setProcessState.succeed()
    } catch (error) {
      console.error(error)
      setProcessState.fail()
    }
  }

  if (processState === 'SUCCESS') {
    return (
      <div>Thank you for submitting your feedback. It is much appreciated.</div>
    )
  }

  return (
    <>
      {processState === 'FAILURE' && (
        <div>
          There was an error submitting your feedback. Please try again later.
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <Spacer bottom={1}>
          <BeardRating onChange={setRating} value={rating} />
        </Spacer>

        <Spacer bottom={1}>
          <Textarea
            label="What feedback do you have about the course?"
            onChange={onStatementChange}
            value={statement}
          />
        </Spacer>

        <Spacer bottom={1}>
          <Checkbox
            checked={permission}
            description="Can I publicly share your feedback as an endorsement for the course if it seems fitting and appropriate?"
            label="Permission to use feedback publicly?"
            name="permission"
            onChange={setPermission.toggle}
          />
        </Spacer>

        <Flex justify="flex-end" gap={bs(0.5)}>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Submit Feedback</Button>
        </Flex>
      </form>
    </>
  )
}
