import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gif from '../../../components/Gif';
import Spacer from '../../../components/Spacer';
import FoldItIn from '../images/foldItIn.gif';
import FoldItInStatic from '../images/foldItInStatic.jpg';
export const _frontmatter = {
  "slug": "reduce-api",
  "title": "The `reduce` API",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`The `}<inlineCode parentName="h1">{`reduce`}</inlineCode>{` API`}</h1>
    <p><inlineCode parentName="p">{`reduce`}</inlineCode>{` and its counterpart, `}<inlineCode parentName="p">{`reduceRight`}</inlineCode>{`, are derivatives of the functional programming concept of "folding". To fold, is to compress, or `}<em parentName="p">{`reduce`}</em>{`, a data structure of values into a single value. Sound familiar?`}</p>
    <Spacer bottom={1} mdxType="Spacer">
  <Gif alt={`Moira Schitt saying "You just fold it in"`} gifSrc={FoldItIn} staticSrc={FoldItInStatic} mdxType="Gif" />
    </Spacer>
    <p><inlineCode parentName="p">{`reduce`}</inlineCode>{` and `}<inlineCode parentName="p">{`reduceRight`}</inlineCode>{` have the same API. Both take a `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function, and an optional `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`. Let's look at the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` first.`}</p>
    <h2><inlineCode parentName="h2">{`reducer`}</inlineCode></h2>
    <p>{`The `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` is a function that will be called for every item in the collection. It receives four arguments: the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{`, the `}<inlineCode parentName="p">{`currentItem`}</inlineCode>{`, the `}<inlineCode parentName="p">{`index`}</inlineCode>{` of that item, and the `}<inlineCode parentName="p">{`array`}</inlineCode>{` on which `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` was called.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`reducer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`accumulatedValue`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` currentItem`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` index`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// return the next accumulatedValue`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The latter three, `}<inlineCode parentName="p">{`currentItem`}</inlineCode>{`, `}<inlineCode parentName="p">{`index`}</inlineCode>{`, and `}<inlineCode parentName="p">{`array`}</inlineCode>{`, are fairly self explanatory. However, the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` causes many people a lot of confusion, so let's break that down a bit further.`}</p>
    <h3><inlineCode parentName="h3">{`accumulatedValue`}</inlineCode></h3>
    <p>{`When we `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` a collection of items, we are taking many items and returning a single item. As I said in the previous lesson, sometimes that single item is a primitive value like a `}<inlineCode parentName="p">{`Boolean`}</inlineCode>{`, `}<inlineCode parentName="p">{`String`}</inlineCode>{` or a `}<inlineCode parentName="p">{`Number`}</inlineCode>{`. Other times, we're building up an `}<inlineCode parentName="p">{`Object`}</inlineCode>{` or an `}<inlineCode parentName="p">{`Array`}</inlineCode>{` from the collection of values. In both cases, we should think of it as returning a single item. I like to tell people that they should think of the outermost symbols, like `}<inlineCode parentName="p">{`{}`}</inlineCode>{` or `}<inlineCode parentName="p">{`[]`}</inlineCode>{` as the single item, ignoring that they might have many values inside them.`}</p>
    <p>{`This single item `}<em parentName="p">{`is`}</em>{` the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{`.`}</p>
    <p>{`For each iteration in a reduction, we pass the current `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` to the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function. We then update the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` with the result from `}<inlineCode parentName="p">{`reducer`}</inlineCode>{`. Therefore, with each iteration, we use the current `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` to create the next `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{`. We'll see this clearly in the next lesson when we re-implement `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` for ourselves.`}</p>
    <p>{`What this `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` starts as depends on the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` argument.`}</p>
    <h2><inlineCode parentName="h2">{`initialValue`}</inlineCode></h2>
    <p>{`The `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` argument is optional. Let's go over how it works when you pass a value in first.`}</p>
    <p>{`Providing `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` with an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` argument sets the initial `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` to that `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`. For example, if you were to call reduce on some numbers, like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` cur`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` acc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` cur
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` initialValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` numbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`add`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` initialValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We can see that `}<inlineCode parentName="p">{`0`}</inlineCode>{` is the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`, therefore the first call of `}<inlineCode parentName="p">{`add`}</inlineCode>{` would be given the arguments `}<inlineCode parentName="p">{`0`}</inlineCode>{` and `}<inlineCode parentName="p">{`1`}</inlineCode>{`. Those would be added, and their result of `}<inlineCode parentName="p">{`1`}</inlineCode>{` would be passed in as the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` argument on the next iteration.`}</p>
    <p>{`That said, the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` argument is optional, so what happens when it isn't provided.`}</p>
    <p>{`First, if there is any chance that `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` might be called on an empty array, an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` must be provided. `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` will throw an error if it's not.`}</p>
    <p>{`Second, and this is a bit tricky, calling `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` without an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` will only work if the items in the array are homogenous, that is of the same type, and the result of the `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` function is `}<em parentName="p">{`also`}</em>{` the same type as the items in the list. In concrete terms using our previous example, because our values are all `}<inlineCode parentName="p">{`Number`}</inlineCode>{`s and `}<inlineCode parentName="p">{`add`}</inlineCode>{` returns a `}<inlineCode parentName="p">{`Number`}</inlineCode>{`, it is possible to skip passing in an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`. What happens when we don't?`}</p>
    <p>{`When an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` is not provided, the first item in the collection is set to the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` and the starting `}<inlineCode parentName="p">{`index`}</inlineCode>{` for iteration is incremented. If we did:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` cur`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` acc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` cur
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` numbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`add`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`This will work. `}<inlineCode parentName="p">{`add`}</inlineCode>{` will only be called twice. The first call will pass in `}<inlineCode parentName="p">{`1`}</inlineCode>{`, the first item in the list, as the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{`, and `}<inlineCode parentName="p">{`2`}</inlineCode>{` as the `}<inlineCode parentName="p">{`currentItem`}</inlineCode>{`. The result of `}<inlineCode parentName="p">{`3`}</inlineCode>{` will be set to the next `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{` on the following iteration.`}</p>
    <p>{`It is exceedingly rare that you will ever use `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` and `}<inlineCode parentName="p">{`reduceRight`}</inlineCode>{` without an `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`. I recommend that you get in the habit of providing an argument for it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      