import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Array.reduce() Overview`}</h1>
    <p>{`Welcome to my course on `}<inlineCode parentName="p">{`Array.prototype.reduce`}</inlineCode>{`! I'm excited that you're taking this opportunity to really get to know this powerful array method.`}</p>
    <h2>{`What to expect`}</h2>
    <p>{`Almost every lesson is a combination of a video component and a written component. While not exact replications, the video follows the learning arc of the written material. My hope is that having two mediums that cover the material will help you learn it better and provide you a place of reference should you need to go over the material again.`}</p>
    <h2>{`Course materials`}</h2>
    <p>{`You can get the materials for the course `}<a parentName="p" {...{
        "href": "https://github.com/kyleshevlin/array-reduce-course-material"
      }}>{`here`}</a>{`. The `}<inlineCode parentName="p">{`main`}</inlineCode>{` branch is a skeleton for each lesson. You can clone the repo and follow along. You can then compare your work to the `}<inlineCode parentName="p">{`solutions`}</inlineCode>{` branch, which has solutions for every exercise.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      