import React from 'react'
import { navigate } from 'gatsby'
import { useAuthContext } from '../contexts/AuthContext'
import { usePurchaseContext } from '../contexts/PurchasesContext'

/**
 * This component is intended to wrap around routes for courses.
 * The `courseId` prop is used to verify the user has purchased that course
 * If they have, they may proceed, otherwise boot 'em somewhere else.
 */
export default function AuthorizedRoute({
  courseId,
  component: Component,
  ...rest
}) {
  const [authState] = useAuthContext()
  const [purchaseState, purchases] = usePurchaseContext()

  if (authState === 'NOT_AUTHENTICATED') {
    navigate('/login')
    return null
  }

  if (
    purchaseState === 'SUCCESS' &&
    purchases.map(p => p.course_id).includes(courseId)
  ) {
    return <Component {...rest} />
  }

  navigate('/learn')
  return null
}
