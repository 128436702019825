/**
 * The `/learn` portion of the app is entirely client side since it is all
 * protected. We generate all the client side routes programmatically.
 */

import React from 'react'
import { Link } from 'gatsby'
import { Router } from '@reach/router'
import MainInner from '../../components/MainInner'
import Seo from '../../components/Seo'
import makeRoutesFromCourses from '../../courses/makeRoutesFromCourses'
import { ARRAY_REDUCE_COURSE } from '../../courses/array-reduce'
import { usePurchaseContext } from '../../contexts/PurchasesContext'
import PrivateRoute from '../../components/PrivateRoute'

const COURSE_IDS_TO_TITLES_MAP = {
  'array-reduce': 'Array.reduce()',
}

const ALL_COURSES = [ARRAY_REDUCE_COURSE]

export default function Learn() {
  return (
    <MainInner>
      <Router basepath="/learn">
        <PrivateRoute path="/" component={LearnBase} />
        {makeRoutesFromCourses(ALL_COURSES)}
      </Router>
    </MainInner>
  )
}

function LearnBase() {
  return (
    <>
      <Seo title="Learn" />

      <div>
        <h2>Learn</h2>
        <h3>Your purchases</h3>
        <LearnInner />
      </div>
    </>
  )
}

function LearnInner() {
  const [state, purchases] = usePurchaseContext()

  switch (state) {
    case 'IDLE':
      return <p>Fetching your purchases...</p>

    case 'FAILURE':
      return (
        <p>
          There was an issue fetching your purchases. Please try again later.
          Contact Kyle if the problem persists.
        </p>
      )

    case 'SUCCESS':
      return purchases.length ? (
        <CourseList purchases={purchases} />
      ) : (
        <NoCoursesYet />
      )

    default:
      throw new Error('Impossible state')
  }
}

function CourseList({ purchases }) {
  return (
    <>
      <p>
        Here are the courses you have purchased. Click the link to start
        learning.
      </p>
      <ul css={{ listStylePosition: 'inside' }}>
        {purchases.map(purchase => {
          return (
            <li key={purchase.id}>
              <h4 css={{ display: 'inline-block', marginBottom: 0 }}>
                <Link to={`/learn/${purchase.course_id}`}>
                  {COURSE_IDS_TO_TITLES_MAP[purchase.course_id]}
                </Link>
              </h4>
            </li>
          )
        })}
      </ul>
    </>
  )
}

function NoCoursesYet() {
  return (
    <p>
      Looks like you haven't purchased any courses yet! Head to{' '}
      <Link to="/courses">Courses</Link> to see what's available.
    </p>
  )
}
