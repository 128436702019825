import React from 'react'
import LearnLayout from '../../components/LearnLayout'
import ArrayReduceOverview from './overview.mdx'
import Lesson1 from './lessons/01-reduce-conceptually.mdx'
import Lesson2 from './lessons/02-the-reduce-api.mdx'
import Lesson3 from './lessons/03-build-reduce-from-scratch.mdx'
import Lesson4 from './lessons/04-my-approach.mdx'
import Lesson5 from './lessons/05-exercise-sum-product.mdx'
import Lesson6 from './lessons/06-exercise-min-max.mdx'
import Lesson7 from './lessons/07-exercise-join.mdx'
import Lesson8 from './lessons/08-exercise-index-of.mdx'
import Lesson9 from './lessons/09-exercise-find.mdx'
import Lesson10 from './lessons/10-exercise-includes.mdx'
import Lesson11 from './lessons/11-exercise-every-some.mdx'
import Lesson12 from './lessons/12-exercise-concat.mdx'
import Lesson13 from './lessons/13-exercise-map-and-filter.mdx'
import Lesson14 from './lessons/14-practical-use-counts.mdx'
import Lesson15 from './lessons/15-practical-use-index-by-key.mdx'
import Lesson16 from './lessons/16-practical-use-group-by.mdx'

export const ARRAY_REDUCE_COURSE = {
  title: 'Array.reduce()',
  basepath: 'array-reduce',
  wrapper: ArrayReduceCourse,
  overview: ArrayReduceOverview,
  lessons: [
    {
      content: Lesson1,
      path: 'reduce-conceptually',
      text: '`Reduce` conceptually',
    },
    {
      content: Lesson2,
      path: 'the-reduce-api',
      text: 'The `reduce` API',
    },
    {
      content: Lesson3,
      path: 'build-reduce-from-scratch',
      text: 'Build `reduce` from scratch',
    },
    {
      content: Lesson4,
      path: 'my-approach',
      text: 'My approach',
    },
    {
      content: Lesson5,
      path: 'exercise-sum-product',
      text: 'Exercise: `sum` and `product`',
    },
    {
      content: Lesson6,
      path: 'exercise-min-max',
      text: 'Exercise: `min` and `max`',
    },
    {
      content: Lesson7,
      path: 'exercise-join',
      text: 'Exercise: `join`',
    },
    {
      content: Lesson8,
      path: 'exercise-index-of',
      text: 'Exercise: `indexOf`',
    },
    {
      content: Lesson9,
      path: 'exercise-find',
      text: 'Exercise: `find`',
    },
    {
      content: Lesson10,
      path: 'exercise-includes',
      text: 'Exercise: `includes`',
    },

    {
      content: Lesson11,
      path: 'exercise-every-some',
      text: 'Exercise: `every` and `some`',
    },
    {
      content: Lesson12,
      path: 'exercise-concat',
      text: 'Exercise: `concat`',
    },
    {
      content: Lesson13,
      path: 'exercise-map-filter',
      text: 'Exercise: `map` and `filter`',
    },
    {
      content: Lesson14,
      path: 'practical-use-counts',
      text: 'Practical Use: `counts`',
    },
    {
      content: Lesson15,
      path: 'practical-use-index-by-key',
      text: 'Practical Use: `indexByKey`',
    },
    {
      content: Lesson16,
      path: `practical-use-group-by`,
      text: 'Practical Use: `groupBy`',
    },
  ],
}

function ArrayReduceCourse({ children }) {
  return <LearnLayout course={ARRAY_REDUCE_COURSE}>{children}</LearnLayout>
}
