import React from 'react'

const STATES = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export default function useAsyncProcess() {
  const [state, setState] = React.useState(STATES.IDLE)
  const handlers = {
    start: () => {
      setState(STATES.PENDING)
    },
    fail: () => {
      setState(STATES.FAILURE)
    },
    succeed: () => {
      setState(STATES.SUCCESS)
    },
  }

  return [state, handlers]
}
