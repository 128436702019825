import React from 'react'
import { bs } from '../shevy'

export default function Textarea({
  label,
  value,
  onChange,
  required = false,
  ...rest
}) {
  return (
    <div>
      <label css={{ display: 'flex', flexDirection: 'column' }} htmlFor={label}>
        <span
          css={{
            fontFamily: 'var(--fonts-catamaran)',
            fontWeight: 'bold',
            marginBottom: bs(0.25),
          }}
        >
          {label}
          {required && '*'}
        </span>
        <textarea
          css={{
            backgroundColor: 'var(--components-inputs-background)',
            border: '1px solid var(--colors-offsetMore)',
            borderRadius: 4,
            color: 'var(--components-inputs-text)',
            height: bs(6),
            padding: `${bs(0.375)} ${bs(0.5)}`,
          }}
          id={label}
          value={value}
          onChange={onChange}
          required={required}
          {...rest}
        />
      </label>
    </div>
  )
}
