import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "practical-use-index-by-key",
  "title": "Practical Use: `indexByKey`",
  "published": true
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Spacer = makeShortcode("Spacer");
const Vimeo = makeShortcode("Vimeo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Practical Use: `}<inlineCode parentName="h1">{`indexByKey`}</inlineCode></h1>
    <Spacer bottom={2} mdxType="Spacer">
  <Vimeo src="https://player.vimeo.com/video/611106380?h=14d062a2e4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Practical use: `indexByKey`" mdxType="Vimeo" />
    </Spacer>
    <p>{`Creating an index based on an object key is probably the most common reason I reach for `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` and one every developer should eventually learn. Let me break down "why" before we go into "how".`}</p>
    <h2>{`Why?`}</h2>
    <p>{`Depending on your educational background, you may or may not know about the concept of "Big O notation" in computer science. If you know this already, please feel free to skip ahead. Big O notation is a way of describing the `}<strong parentName="p">{`worse case performance`}</strong>{` of an algorithm. Now, this isn't a course on computer science fundamentals, but let's compare two real scenarios: Finding a value in an array versus finding a value in an object.`}</p>
    <p>{`Let's say we're looking for an item in an array and we don't know its index. How do we find it? In the worst case scenario, we start at the beginning of the array, check each item, and find it in the very last spot. This is described as `}<inlineCode parentName="p">{`O(n)`}</inlineCode>{`, where `}<inlineCode parentName="p">{`n`}</inlineCode>{` represents the number of items in the array. How does this compare to searching through an object?`}</p>
    <p>{`The way we look for an item in an object is with a `}<inlineCode parentName="p">{`key`}</inlineCode>{`. If the object has that `}<inlineCode parentName="p">{`key`}</inlineCode>{`, it provides the value, otherwise it does not. We don't need to look through every item in the object. The result of our search is returned to use, for all intents and purposes, immediately. Or in the parlance of computer science, it's returned in "constant time", represented by `}<inlineCode parentName="p">{`O(1)`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`O(1)`}</inlineCode>{` is `}<em parentName="p">{`much`}</em>{` faster than `}<inlineCode parentName="p">{`O(n)`}</inlineCode>{`, especially as `}<inlineCode parentName="p">{`n`}</inlineCode>{` gets big.`}</p>
    <p>{`Therefore, if we know we're going to be looking up items frequently `}<em parentName="p">{`and`}</em>{` we have a unique identifier for each item we can use as a `}<inlineCode parentName="p">{`key`}</inlineCode>{`, we can put those items into an object and improve our lookup performance. Hence, using `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` to convert an array into an object, AKA `}<inlineCode parentName="p">{`indexByKey`}</inlineCode>{`.`}</p>
    <h2>{`How?`}</h2>
    <p>{`As with our other exercises, let's create some tests that we can use to verify our progress in implementing this functionality.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`describe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'indexByKey'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`it`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'should index by key'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` items `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'asdf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Kyle'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'hjkl'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Anna'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`indexByKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'id'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      asdf`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'asdf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Kyle'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      hjkl`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'hjkl'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Anna'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`indexByKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'name'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      Kyle`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'asdf'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Kyle'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      Anna`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'hjkl'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Anna'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Our function will receive an array of objects an `}<inlineCode parentName="p">{`key`}</inlineCode>{` on those objects by which to index. It is the onus of the user to make sure the items will not have duplicate `}<inlineCode parentName="p">{`key`}</inlineCode>{`s because they will get overwritten.`}</p>
    <p>{`Our implementation is similar to `}<inlineCode parentName="p">{`counts`}</inlineCode>{`, but even simpler:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`indexByKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`items`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` item
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We aren't concerned with checking if a `}<inlineCode parentName="p">{`key`}</inlineCode>{` is in use already, so we don't need a guard statement to protect against this. Instead, we overwrite the value at that `}<inlineCode parentName="p">{`key`}</inlineCode>{` if there is a duplicate.`}</p>
    <p>{`Now with our index, we can look up items quite quickly based on a `}<inlineCode parentName="p">{`key`}</inlineCode>{`.`}</p>
    <h2><inlineCode parentName="h2">{`for`}</inlineCode>{` loop implementation of `}<inlineCode parentName="h2">{`indexByKey`}</inlineCode></h2>
    <Spacer bottom={2} mdxType="Spacer">
  <Vimeo src="https://player.vimeo.com/video/596693356?h=663053b86f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Refactor `indexByKey` with a `for..of` loop" mdxType="Vimeo" />
    </Spacer>
    <p>{`Again, the `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop version is very straightforward. Build up a `}<inlineCode parentName="p">{`result`}</inlineCode>{` with each item and soon you have your index.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`indexByKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`items`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` item `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`of`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` item
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` result
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      