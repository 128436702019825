import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "my-approach",
  "title": "My approach",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`My approach`}</h1>
    <p>{`It might sound odd, but I have an approach for how I write my reducers. I do the same things almost every time. Because of this repetition, I find that I get my reducers right on the first try more often than not. Give it a try, see what works for you, tweak what doesn't.`}</p>
    <h2>{`Always use `}<inlineCode parentName="h2">{`acc`}</inlineCode>{` and `}<inlineCode parentName="h2">{`cur`}</inlineCode></h2>
    <p>{`For whatever odd reason, I find reducers easier to read when I always use the same variable names for the function. I use `}<inlineCode parentName="p">{`acc`}</inlineCode>{` for the "accumulated value", and I use `}<inlineCode parentName="p">{`cur`}</inlineCode>{` for the "current item". A lot of people don't like this, but it works for my brain.`}</p>
    <p>{`By always using the same variable names in a reducer function, I find that I can quickly orient myself and understand what's happening. "Ahh, we're returning `}<inlineCode parentName="p">{`acc`}</inlineCode>{` in the guard statement!" "Oh, the `}<inlineCode parentName="p">{`cur`}</inlineCode>{`rentItem is getting mutated in this way." `}<strong parentName="p">{`It just clicks for me.`}</strong>{` I don't lose time or focus trying to remember that a variable like `}<inlineCode parentName="p">{`obj`}</inlineCode>{` or `}<inlineCode parentName="p">{`result`}</inlineCode>{` needs to be returned, or the `}<inlineCode parentName="p">{`item`}</inlineCode>{` or `}<inlineCode parentName="p">{`number`}</inlineCode>{`, etc is.`}</p>
    <p>{`Now, for your sake, and for the sake of decent pedagogy, I'm going to attempt to strike a balance in these lessons. I will still use `}<inlineCode parentName="p">{`acc`}</inlineCode>{` in almost all of my reducers, but I will replace `}<inlineCode parentName="p">{`cur`}</inlineCode>{` with `}<inlineCode parentName="p">{`item`}</inlineCode>{`, or a related variable name. I think that may help you learn `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` better. Only time will tell.`}</p>
    <h2>{`Write `}<inlineCode parentName="h2">{`return acc`}</inlineCode>{` before anything else.`}</h2>
    <p>{`Almost every reducer you write will have this line at the end of its function body: `}<inlineCode parentName="p">{`return acc`}</inlineCode>{`. Because it is so common, I almost always write this `}<em parentName="p">{`before`}</em>{` I write the rest of the logic of the function. It helps to make sure you never forget to return the next accumulated value, and its a useful starting point for figuring out the logic of your reducer.`}</p>
    <p>{`As you'll see in some lessons, you may end up writing `}<inlineCode parentName="p">{`return acc`}</inlineCode>{` multiple times in a reducer body due to various guard statements. That's ok. Focus on keeping the `}<inlineCode parentName="p">{`return`}</inlineCode>{` of your `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` as straightforward as possible and it will make writing them much easier.`}</p>
    <h2>{`Always use an `}<inlineCode parentName="h2">{`initialValue`}</inlineCode></h2>
    <p>{`As stated in the API lesson, most reducers will make use of the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` argument. It guards against the possibility of an empty array calling `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` and throwing an error, and its often essential for creating the single item you intend to return from the `}<inlineCode parentName="p">{`accumulatedValue`}</inlineCode>{`. Be sure you get this right. Ask yourself, "What do I want to return from this?" If you're using types, this is the place to get the resulting type figured out. Do this before writing they body of your `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` and then develop the logic from there.`}</p>
    <h2>{`Multiple guards are better than a big `}<inlineCode parentName="h2">{`return`}</inlineCode>{` statement`}</h2>
    <p>{`You'll see in the lessons, that I will generally opt for several short guard statements rather than try and add a ternary or some kind of condition to the return. Without getting too far ahead, the `}<inlineCode parentName="p">{`find`}</inlineCode>{` function is a good example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`items`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` predicate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`acc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`predicate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` item

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` acc
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I personally find it easier to write my `}<inlineCode parentName="p">{`reducer`}</inlineCode>{` functions this way. It's almost like pattern matching. Each guard covers a single case, and we `}<inlineCode parentName="p">{`return`}</inlineCode>{` what we need to right away.`}</p>
    <p>{`Those are my tips. Try them out, see if any of them stick for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      